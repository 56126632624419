export const articles1 = [
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/fortuneindia_2021-02_78fb62d6-e1a9-46df-bcb0-68c080ea3f32_Praveen_Gupta__1__7jFfqY2ir.png',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/fortuneindia_2021-02_78fb62d6-e1a9-46df-bcb0-68c080ea3f32_Praveen_Gupta__1__7jFfqY2ir.png',
    },
    title: "A nation rich in biotechnology can lead the world",
    subtitle:
      "An interview with Mr. Praveen Gupta by Enterprise World, and get known to the Premas Life Sciences’ quality offerings",
      href:"https://theenterpriseworld.com/praveen-gupta-a-game-changing-leader-in-the-field-of-life-sciences/",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/Logo-White-2048x340_RFLad1uDLj.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/Logo-White-2048x340_RFLad1uDLj.png',
        },
        name: 'The enterprise world',
      },
    date: '26 Aug 2021',
    tags: ['Mr. Praveen Gupta, MD & CEO', 'Premas Life Sciences (PLS)',],
  },
  {
    cover:  {
      src: 'https://ik.imagekit.io/j6phbjneuop/content2_BQsB7BHba.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/content2_BQsB7BHba.jpg',
    },
    title: 'Premas Life Sciences: India’s Top Brand in Molecular Biology, Genetics & Genomics Solutions',
    subtitle:
      'Watch Praveen Gupta in his latest interview to Fortune india ',
      href: 'https://www.fortuneindia.com/technology/premas-life-sciences-indias-top-brand-in-molecular-biology-genetics-genomics-solutions/105174',
    author: {
      photo: {
        src: 'https://ik.imagekit.io/j6phbjneuop/fortune_39wTPGQP8.jpg?updatedAt=1629962312719',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/fortune_39wTPGQP8.jpg?updatedAt=1629962312719',
      },
      name: 'Fortune India Exchange',
    },
    date: '10 feb 2021',
    tags: ['Covid -19', 'disruptive ', 'diagnostics ', 'Single Cell research',  'Technology'],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/Premas-Life-Sciences-for-web_uR9pM2X5M.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/Premas-Life-Sciences-for-web_uR9pM2X5M.jpg',
    },
    title: "TRANSFORMING THE GENOMICS LANDSCAPE IN INDIA",
    subtitle:
      'Dr. Debjani Saha, Group Product Manager illustrate motto , work culture and vision for the future',
      href:"https://theceostory.in/the-companies-booming-despite-the-lockdown/premas-life-sciences/",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__2__lmkR0KLuY6.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__2__lmkR0KLuY6.jpg',
        },
        name: 'The CEO story',
      },
    date: '6 Aug 2020',
    tags: ['Society', 'Sahyog', ' Next Generation Sequencing', ' CSR',  ],
  },

  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/1-16186__1__DJu3uZ8c_.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/1-16186__1__DJu3uZ8c_.jpeg',
    },
    title: 'We might see another bout in Nov-Dec',
    subtitle:
      'Praveen Gupta, Managing Director, Premas Life Sciences shares his views on the ongoing COVID-19 situation',
      href:"https://www.biospectrumindia.com/views/83/16186/we-might-see-another-bout-in-nov-dec-praveen-gupta.html",
    author: {
      photo: {
        src: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
      },
      name: 'Big Spectrum',
    },
    date: '20 April 2020',
    tags: ['Covid -19', 'Virus', 'samples', 'Hospitals', 'asymptomatic', 'immunity'],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/pexels-photo-6436181_ret-sQoJ5.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/pexels-photo-6436181_ret-sQoJ5.jpeg',
    },
    title: "We see an immense potential in healthcare and Agribio domains",
    subtitle:
      "Yash Ved from NewsBarons provides highlights of an interaction with Praveen Gupta, Executive Director, Premas Life Sciences who informed ‘Healthcare in India is poised for continued modernisation by adopting newer technologies.",
      href: "https://newsbarons.com/healthcare/see-immense-potential-healthcare-agribio-domains-praveen-gupta-premas-lifesciences/",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/Logo-NB-270x90-optimized_FHkXbQHFX.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/Logo-NB-270x90-optimized_FHkXbQHFX.png',
        },
        name: 'News Barons',
      },
    date: '27 December 2017',
    tags: ['Analytical Technologies', 'pharmacogenomics', 'Illumina', 'Genetic'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/praveen-gupta-premas-life-science_Zqg85dMQe.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/praveen-gupta-premas-life-science_Zqg85dMQe.jpg',
    },
    title: 'Genomics market is getting a major push',
    subtitle:
      'Watch Praveen Gupta , talking about current trends in the Indian genomics and sequencing market in a candid talk with Rahul koul',
      href:"https://www.biospectrumindia.com/interviews/74/6837/genomics-market-is-getting-a-major-push.html",
    author: {
      photo: {
        src: 'https://ik.imagekit.io/j6phbjneuop/Ngs_-csB1P8BC.jpg',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/Ngs_-csB1P8BC.jpg',
      },
      name: 'Big Spectrum',
    },
    date: '24 June 2015',
    tags: ['Genomics', 'NGS', 'Biosuppliers', 'Global'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/fusspng_m3P5G6-lPo.png',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/fusspng_m3P5G6-lPo.png',
    },
    title: 'THE DISRUPTION AND THE DIFFERENCE ',
    subtitle:
      'Mr. sanjav Yadav shares his insight on the work culture of Premas lifesciences',
      href:"https://www.voiceofhealthcare.org/idealleader.php?id=1164",
    author: {
      photo: {
        src: 'https://ik.imagekit.io/j6phbjneuop/logo_0IlASvgoF.png',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo_0IlASvgoF.png',
      },
      name: 'VOH',
    },
    date: '24 June 2015',
    tags: ['Genomics', 'NGS', 'Biosuppliers', 'Global'],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/2860e7d5-9316-42a7-8691-c64bddf4a3f4_ql9D2q7Z5.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/2860e7d5-9316-42a7-8691-c64bddf4a3f4_ql9D2q7Z5.jpg',
    },
    title: "Premas Biotech gets Illumina's best distributor award",
    subtitle:
      "Illlumina's global distributors meet cum awards was held at San Diego in California",
      href:"https://www.biospectrumindia.com/news/74/5726/premas-biotech-gets-illuminas-best-distributor-award.html",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
        },
        name: 'Big Spectrum',
      },
    date: '16 october 2014',
    tags: ['Genomics', 'NGS', 'Biosuppliers', 'Global'],
  },
  
  
  
];
export const articles2 = [
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/pexels-ketut-subiyanto-4308175_jUymXmbfdB.jpg?updatedAt=1629974173207',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/pexels-ketut-subiyanto-4308175_jUymXmbfdB.jpg?updatedAt=1629974173207',
    },
    title: "GenScript Biotech Corporation Enters Strategic Distributor Partnership with Premas Life Sciences‎",
    subtitle:
      '  Distribution of cPassTM SARS CoV-2 Neutralization Antibody Detection Kit in India',
      href:"https://www.outlookindia.com/newsscroll/genscript-biotech-corporation-enters-strategic-distributor-partnership-with-premas-life-sciences-to-distribute-cpasstm-sars-cov2-neutralization-antibody-detection-kit-in-india/1914210",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__d7fMRqSBE.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__d7fMRqSBE.jpg',
        },
        name: 'Outlook',
      },
    date: '12 Aug 2020',
    tags: ['cPass™', 'biosafety', ' in-depth technical support', 'community', 'forensic testing', ],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/pexels-photo-7542754_1__inHXuaKe3.jpeg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/pexels-photo-7542754_1__inHXuaKe3.jpeg',
    },
    title: "Extract 48 COVID RNA Samples in Just 17 Minutes with the Latest Technology from Genolution & Premas Life Sciences",
    subtitle:
      ' Premas Life Sciences (PLS) and Genolution (a South Korea based company) has announced a new sales distribution agreement, appointing Premas Life Sciences as technology and knowledge distributor of Nextractor NX-48S Platform in India',
      href: "https://www.newsvoir.com/release/extract-48-covid-rna-samples-in-just-17-minutes-with-the-latest-technology-from-genolution-premas-life-sciences-13927.html",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__2__lmkR0KLuY6.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__2__lmkR0KLuY6.jpg',
        },
        name: 'NewsVoir',
      },
    date: '27 may 2020',
    tags: ['Nextractor NX-48S Platform', '48-sample extraction of DNA/RNA ' ],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/InkedBioXp-3200-Codex_LI_sdn0PZYvS.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/InkedBioXp-3200-Codex_LI_sdn0PZYvS.jpg',
    },
    title: "Codex and Premas Life Sciences Partner to launch world’s first and only fully automated Gene Synthesis Platform in India",
    subtitle:
      ' Through this partnership, Premas Life Sciences will empower Codex’s revolutionizing technology that can significantly ramp up the current efforts in #COVID19 vaccine development',
      href:"https://www.expresspharma.in/codex-and-premas-life-sciences-partner-to-launch-worlds-first-and-only-fully-automated-gene-synthesis-platform-in-india/",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/ep-2x_CBIWw5TnD.webp',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/ep-2x_CBIWw5TnD.webp',
        },
        name: 'EP News Bureau',
      },
    date: '15 may 2020',
    tags: ['cPass™', 'biosafety', ' in-depth technical support', 'community', 'forensic testing', ],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/matt_glcFKziLL.png',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/matt_glcFKziLL.png',
    },
    title: "Premas Life Sciences, IncellDx sign distribution agreement for cell multiplex diagnostics for solid tumours",
    subtitle:
      ' The commercial partnership brings the power of cell by cell multiplex diagnostics for solid tumours carcinomas that can now be analyzed for molecular and protein biomarkers by flow cytometry.',
      href:"http://pharmabiz.com/NewsDetails.aspx?aid=106906&sid=2",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__2__lmkR0KLuY6.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__2__lmkR0KLuY6.jpg',
        },
        name: 'Pharamabiz',
      },
    date: '25 Jan 2018',
    tags: ['IncellDx', 'multiplex diagnostics', ' Analysis', ' quantification', 'Bruce Patterson' ],
  },
  
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/11-premas-prabhudha-kundu_9OTZU3Fk0.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/11-premas-prabhudha-kundu_9OTZU3Fk0.jpg',
    },
    title: "Premas registers nearly 132 percent growth‎",
    subtitle:
      'Dr Prabuddha K Kundu, executive director in Talk with BioSpectrum Bureau on achieving high growth revenue',
      href:"https://www.biospectrumindia.com/features/74/5583/premas-registers-nearly-132-percent-growth.html",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
        },
        name: 'Big Spectrum',
      },
    date: '11 september 2014',
    tags: ['Premas-life-sciences', 'molecular technologies', 'genomics equipments', 'genetic diagnosis', 'forensic testing', 'laboratory productivity.'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/mysterious-microbes-web-graphic_cLNTd4hNX.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/mysterious-microbes-web-graphic_cLNTd4hNX.jpg',
    },
    title: "Premas bets big on Illumina's product range",
    subtitle:
      "Since January 1, 2013, New Delhi based biosupplier division of Premas Biotech became the sole sales channel partner across India for marketing of Illumina's sequencing, array and related product lines",
      href:"https://www.biospectrumindia.com/news/18/561/premas-bets-big-on-illuminas-product-range-.html",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo_9K_zElz3MD.jpg',
        },
        name: 'Big Spectrum',
      },
    date: '01 feb 2013',
    tags: ['Illumina', 'Molecular biology', 'PCR', 'Award-winning'],
  },

];

export const articles3 = [
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/screenshot.png__xDrZIoNQ.jpg?updatedAt=1625212899629',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/screenshot.png__xDrZIoNQ.jpg?updatedAt=1625212899629',
    },
    title: 'Genomes – Redefining breakthroughs in Research & Discovery',
    subtitle:
      'At Premas Life Sciences, we are facilitating the whole process from scratch – with game-changing technologies assisting the Indian researchers at the frontlines.',
      href:'https://www.voiceofhealthcare.org/blogdetails.php?id=1026',
    author: {
      photo: {
        src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
      },
      name: ' Dr Tani Agarwal ',
    },
    date: '17 may 2021',
    tags: ['SARS-CoV-2' , 'accelerated' , 'prediction'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/15eca5a25860ce22abdbcb776a919b7f_zk_CWs83C8',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/15eca5a25860ce22abdbcb776a919b7f_zk_CWs83C8',
    },
    title: 'Comprehensive Genomic Profiling (CGP): Modern standard for personalized cancer care',
    subtitle:
      'Cancer, a diverse group of diseases is driven by results of genetic alterations that facilitates to aberrant and uncontrolled cellular proliferation. ',
    href :"https://www.voiceofhealthcare.org/blogdetails.php?id=1034",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
      name: ' Dr Ashutosh Upadhyay',
    },
    date: '14 Jun-2021',
    tags: ['cancer', 'genetic', 'epigenetics', 'diagnosis'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/fix_gEHwZR6-ur.png',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/fix_gEHwZR6-ur.png',
    },
    title: "Future Healthcare: Focused on Scope of Clinical Informatics",
    subtitle:
      'Modern medicine is a dynamic science which connects the dots between the biomedical and Data science.',
   href:"https://www.voiceofhealthcare.org/blogdetails.php?id=1036",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
      name: ' Rajavarman Kittu',
    },
    date: ' 21 Jul-2021',
    tags: ['analytical ', 'data', 'Implementation', 'management', ],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/55a16a5f418958343f18e7abcf9efa2e__1__OFKM-H6mqq',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/55a16a5f418958343f18e7abcf9efa2e__1__OFKM-H6mqq',
    },
    title: "This thrilling discipline of genome engineering is pushing boundaries in healthcare",
    subtitle:
      'Looking at the recent pandemic times topped with heavy challenges for public health; Synthetic biology emerged as the most powerful weapon to combat against them.',
   href:"https://www.voiceofhealthcare.org/blogdetails.php?id=1023",
      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
      name: 'Ayushi Saxena',
    },
    date: ' 21 Mar-2021',
    tags: ['SYNTHETIC BIOLOGY.', 'BioXp 3250 system,', 'Codex Dna', ],
  },
];


export const articles4 = [
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/ezgif.com-gif-maker__7__5bmR5y1KA.gif?updatedAt=1625473274689',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/ezgif.com-gif-maker__7__5bmR5y1KA.gif?updatedAt=1625473274689',
    },
    title: 'Rare diseases: Rare or Invisible? ',
    subtitle:
      'We need to spread awareness in society and bring up a generation more sensible towards rare disease cues and needs along with aiming for better government strategies and envision more cures and care for them.',

    author: {
      photo: {
        src: 'https://ik.imagekit.io/j6phbjneuop/bhamini_pdVrKvAw4.png',
        srcSet: 'https://ik.imagekit.io/j6phbjneuop/bhamini_pdVrKvAw4.png',
      },
      name: 'Bhamini Arora :Product Executive (Clinical), Premas Life Sciences ',
    
    },
    date: 'May 20, 2019',
    tags: ['SARS-CoV-2' , 'accelerated' , 'prediction'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/blood-sample-tube-nipt-test-260nw-1716717703_XDbfNvweD.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/blood-sample-tube-nipt-test-260nw-1716717703_XDbfNvweD.jpg',
    },
    title: 'So what is stopping you now to opt for an NIPT test in your labs ?',
    subtitle:
      'NIPT refers specifically to techniques that evaluate fetal cells or cfDNA in a blood sample drawn from the mother during pregnancy. ',

      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
        name: 'Bhamini Arora :Product Executive (Clinical), Premas Life Sciences ',
    },
    date: '14 Jun-2021',
    tags: ['cancer', 'genetic', 'epigenetics', 'diagnosis'],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/organ-donation-4301527_1280_0_GQRCgL3J8i.webp',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/organ-donation-4301527_1280_0_GQRCgL3J8i.webp',
    },
    title: "1 Yes equals to 8 lives",
    subtitle:
      'Your well-being changes from being hopeless to hopeful to finally live better times ahead – Anonymous Organ Recipient ',

      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
        name: 'Bhamini Arora :Product Executive (Clinical), Premas Life Sciences '
    },
    date: ' 21 Jul-2021',
    tags: ['analytical ', 'data', 'Implementation', 'management', ],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/Guide_Genomics_dynamic2020-01_FTkVY_kPu.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/Guide_Genomics_dynamic2020-01_FTkVY_kPu.jpg',
    },
    title: "Genomicist: A Superhero",
    subtitle:
      'Wondering, about how to handle both the data analysis and data management with limited turn-around time???',

      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
      name: 'Rajavarman Kittu (Clinical Bioinformatics Specialist @Premas)',
    },
    date: ' 21 Mar-2021',
    tags: ['SYNTHETIC BIOLOGY.', 'BioXp 3250 system,', 'Codex Dna', ],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/2020-08-what-is-next-generation-sequencing_lNBadD52Nn.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/2020-08-what-is-next-generation-sequencing_lNBadD52Nn.jpg',
    },
    title: "Illumina – Powering the era of NGS",
    subtitle:
      'The story of DNA sequencing goes back to 1977, when Frederick Sanger developed Sanger sequencing',

      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
      name: 'Dr. Madhu Baghel Product Manager, Premas Life Sciences',
    },
    date: ' 21 Mar-2021',
    tags: ['SYNTHETIC BIOLOGY.', 'BioXp 3250 system,', 'Codex Dna', ],
  },
  {
    cover: {
      src: 'https://ik.imagekit.io/j6phbjneuop/pexels-rodnae-productions-7403370_jWDAbJp2_.jpg',
      srcSet: 'https://ik.imagekit.io/j6phbjneuop/pexels-rodnae-productions-7403370_jWDAbJp2_.jpg',
    },
    title: "Who is Special!",
    subtitle:
      'Yes! We all have our own special ones in life with different relationship tags. No matter what, we don’t give up on them easily',

      author: {
        photo: {
          src: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
          srcSet: 'https://ik.imagekit.io/j6phbjneuop/logo__1__HB0xXp_b8RN.png',
        },
      name: 'Rajavarman Kittu (Clinical Bioinformatics Specialist @Premas)',
    },
    date: ' 21 Mar-2021',
    tags: ['SYNTHETIC BIOLOGY.', 'BioXp 3250 system,', 'Codex Dna', ],
  },
];
