import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import {  Divider ,   Grid, Typography} from '@material-ui/core';
import { Section, SectionAlternate } from 'components/organisms';
import {
  Hero,
  Horizontal,
//  VerticalTransparent,
HorizontalTransparent ,


  VerticalOverlaped,
  Vertical,

} from './components';

import { Subscription } from 'views/DesignCompany/components';

import { articles1, articles2, articles3, articles4,} from './data';
import { SectionHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%',
  },
  pagePaddingTop: {
    paddingTop: theme.spacing(3),
    paddingBottom: theme.spacing(3),
    [theme.breakpoints.up('md')]: {
      paddingTop: theme.spacing(5),
      paddingBottom: theme.spacing(5),
    },
  },
  tags: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  tag: {
    padding: theme.spacing(1 / 2, 1),
    borderRadius: theme.spacing(1 / 2),
    background: theme.palette.secondary.light,
    color: 'white',
    margin: theme.spacing(0, 1, 1, 0),
    cursor: 'pointer',
    [theme.breakpoints.up('md')]: {
      margin: theme.spacing(0, 2, 2, 0),
    },
  },
}));

const BlogReachView = () => {
  const classes = useStyles();
  const scrollTo = id => {
    setTimeout(() => {
      const element = document.querySelector(`#${id}`);
      if (!element) {
        return;
      }

      window.scrollTo({ left: 0, top: element.offsetTop, behavior: 'smooth' });
    });
  };

  return (
    <div className={classes.root}>
      <Section className={classes.pagePaddingTop}>
        <Hero />
        <Grid item xs={12}>
        <div className={classes.tags}>
        <Typography
        onClick={() => scrollTo('plsfeatured')}
                variant="caption"
                color="primary"
                className={classes.tag}
               
              >
               #plsfeatured
              </Typography>
              <Typography
        onClick={() => scrollTo('plspartnerships')}
                variant="caption"
                color="primary"
                className={classes.tag}
               
              >
               #plspartnerships
              </Typography>
              <Typography
        onClick={() => scrollTo('plspenned')}
                variant="caption"
                color="primary"
                className={classes.tag}
               
              >
               #plspenned
              </Typography>
              <Typography
        onClick={() => scrollTo('plsblog')}
                variant="caption"
                color="primary"
                className={classes.tag}
               
              >
               #plsblog
              </Typography>
  
 
  
   </div>
   </Grid>
      </Section>
      <SectionAlternate>
        <>
        <SectionHeader id = "plsfeatured" title = "#plsfeatured " />
        <VerticalOverlaped data={articles1} />
        <Section>
          <Divider />
        </Section>
  
      
       
        <SectionHeader id = "plspartnerships" title  = "#plspartnerships" />
        <Horizontal data={articles2} />
       
  
        <Section>
          <Divider />
        </Section>
        {/* <SectionHeader title = "#plspartnerships" /> */}
        {/* <Vertical data={articles2} /> */}
        <SectionHeader id = "plspenned" title = "#plspenned " />
        <Vertical data={articles3} />
        <Section>
          <Divider />
        </Section>
        <SectionHeader id ="plsblog" title = "#plsblog " />
        <HorizontalTransparent data={articles4} />
    
 
        </>
      </SectionAlternate>
      <Subscription/>

    </div>
  );
};

export default BlogReachView;
